import {App} from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import jwtService from "@/core/services/jwtService";
import {AxiosResponse, AxiosRequestConfig} from "axios";
import {apiHost, apiVersion, apiClient} from "@/core/config/config";
import store from "@/store";
import {Actions, Configs} from "@/store/enums/store.enums";
import router from "@/routes";

/**
 * @description service to call HTTP request via Axios
 */
class apiService {
    /**
     * @description property to share vue instance
     */
    public static vueInstance: App;

    private static checkTokenRefreshRoute(route) {
        return Configs.NON_TOKEN_REFRESH_ROUTES.includes(route);
    }

    private static getFallbackResult() {

        //console.log('getFallbackResult');
        return {
            data: {
                error: true,
                message: 'apiError',
                result: {}
            }
        };

    }

    private static resolveEmpty() {

        store.dispatch(Actions.RESOLVE_EMPTY).then(async () => {
            return Promise.resolve().catch(() => {
            });
        });

    }

    private static reject(error: any, logout = false) {

        // @ToDo System eigene Info über einen Reject?
        //console.log('reject --> error', error?.message);

        if (logout) {

            store.dispatch(Actions.LOGOUT).then(() => {

                router.push({name: "vo-login"}).then(() => {
                    return Promise.reject(error);
                })

            });

        } else {

            return Promise.reject(error);

        }

    }

    /**
     * @description initialize vue axios
     */
    public static init(app: App<Element>) {

        const host = apiHost.value || 'http://localhost';
        const version = apiVersion.value || 'v2';

        apiService.vueInstance = app;
        apiService.vueInstance.use(VueAxios, axios);
        apiService.vueInstance.axios.defaults.baseURL = host + '/' + version + '/';

        apiService.vueInstance.axios.interceptors.response.use(
            (res) => {
                return res;
            },
            async (error) => {

                const originalConfig = error?.config || {};

                const {url} = originalConfig;
                const excludeRefreshRoute = apiService.checkTokenRefreshRoute(url);

                // Precondition Failed - Token Expired //
                if (error.response.status === 412 && !originalConfig._retry && error.response) {

                    try {

                        // no new token for this given route //
                        if (excludeRefreshRoute) {
                            return apiService.resolveEmpty();
                        }

                        originalConfig._retry = true;

                        // get current refresh token //
                        const refreshToken = jwtService.getRefreshToken();
                        if (refreshToken) {

                            // refresh token including refresh token //
                            await store.dispatch(Actions.REFRESH_AUTH, {refreshToken});

                            // change expired token from original request to new refreshed token//
                            const token = jwtService.getToken();
                            if (token) {

                                originalConfig.headers.Authorization = `Bearer ${token}`;

                                // return original request //
                                return apiService.vueInstance.axios(originalConfig);

                            } else {

                                return apiService.reject(error, true);

                            }

                        } else {

                            return apiService.reject(error, true);

                        }

                    } catch (_error) {

                        return apiService.reject(error);

                    }

                }

                return apiService.reject(error);

            }
        );

    }

    public static destroyAuthorization() {
        apiService.vueInstance.axios.defaults.headers.common["Authorization"] = null;
    }

    /**
     * @description set the default HTTP request headers
     */
    public static setHeader() {

        return new Promise(resolve => {

            const client = apiClient.value || '';
            if (client) {
                apiService.vueInstance.axios.defaults.headers.common["Vo-Api-Client"] = client;
            }

            const token = jwtService.getToken();
            if (token) {
                apiService.vueInstance.axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            }

            resolve(true);

        });

    }

    public static getApiError(error: any) {

        //console.log('getApiError --> ', error);

        // check for error response from API //
        if (error?.response) {
            return error.response;
        }

        return 'unknownError';

    }

    /**
     * @description check for needed error var in API Response
     */
    public static verifyApiResult(response: any) {

        //console.log('verifyApiResult',response);
        if (!response) {
            return false;
        }

        if (typeof response.error === "undefined") {
            return false;
        }

        if (response.error === true) {
            return false;
        }

        return true;

    }

    /**
     * @description send the GET HTTP request
     * @returns Promise<AxiosResponse>
     * @param resource
     * @param params
     */
    public static async query(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {

        await this.setHeader();

        return apiService.vueInstance.axios.get(resource, params).then(response => {

            // catch 412 / 401 responses from api with undefined response //
            if (!response) {
                return apiService.getFallbackResult();
            }

            return response;

        }).catch(error => {

            return this.getApiError(error);

        });
    }

    /**
     * @description send the GET HTTP request
     * @returns Promise<AxiosResponse>
     * @param resource
     * @param slug
     */
    public static async get(resource: string, slug = "" as string): Promise<AxiosResponse> {

        await this.setHeader();

        return apiService.vueInstance.axios.get(`${resource}/${slug}`).then(response => {

            // catch 412 / 401 responses from api with undefined response //
            if (!response) {
                return this.getFallbackResult();
            }

            return response;

        }).catch(error => {

            return this.getApiError(error);

        });
    }

    /**
     * @description set the POST HTTP request
     * @returns Promise<AxiosResponse>
     * @param resource
     * @param params
     */
    public static async post(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {

        await this.setHeader();

        return apiService.vueInstance.axios.post(`${resource}`, params).then(response => {

            // catch 412 / 401 responses from api with undefined response //
            if (!response) {
                return this.getFallbackResult();
            }

            return response;

        }).catch(error => {

            return this.getApiError(error);

        });

    }

    /**
     * @description send the UPDATE HTTP request
     * @returns Promise<AxiosResponse>
     * @param resource
     * @param slug
     * @param params
     */
    public static async update(resource: string, slug: string, params: AxiosRequestConfig): Promise<AxiosResponse> {

        await this.setHeader();

        return apiService.vueInstance.axios.put(`${resource}/${slug}`, params).then(response => {

            // catch 412 / 401 responses from api with undefined response //
            if (!response) {
                return this.getFallbackResult();
            }

            return response;

        }).catch(error => {

            return this.getApiError(error);

        });
    }

    /**
     * @description Send the PUT HTTP request
     * @returns Promise<AxiosResponse>
     * @param resource
     * @param params
     */
    public static async put(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {

        await this.setHeader();

        return apiService.vueInstance.axios.put(`${resource}`, params).then(response => {

            // catch 412 / 401 responses from api with undefined response //
            if (!response) {
                return this.getFallbackResult();
            }

            return response;

        }).catch(error => {

            return this.getApiError(error);

        });
    }

    /**
     * @description Send the DELETE HTTP request
     * @returns Promise<AxiosResponse>
     * @param resource
     */
    public static async delete(resource: string): Promise<AxiosResponse> {

        await this.setHeader();

        return apiService.vueInstance.axios.delete(resource).then(response => {

            // catch 412 / 401 responses from api with undefined response //
            if (!response) {
                return this.getFallbackResult();
            }

            return response;

        }).catch(error => {

            return this.getApiError(error);

        });
    }
}

export default apiService;
