// ENV VARS //
const theme = process.env.VUE_APP_THEME || 'virtualoffice';

const exportTheme = {
    virtualoffice: {
        "name": "Virtualoffice",
        "apiHost": "https://api.virtual-office.solutions",
        "apiVersion": "v2",
        "apiClient": "$zet&8#234-rt456",
        "version": "v4.0.0",
        "fullName": "Virtualoffice Solutions :: ",
        "loader": {
            "display": true,
            "type": "default"
        },
        "scrollTop": {
            "display": false
        },
        "header": {
            "showSearch": false,
            "display": true,
            "width": "fluid",
            "fixed": {
                "desktop": true,
                "tabletAndMobile": true
            }
        },
        "megaMenu": {
            "display": false
        },
        "aside": {
            "display": true,
            "fixed": true,
            "menu": "main",
            "primaryDisplay": true,
            "secondaryDisplay": true,
            "secondaryDisplayAsHover": false,
            "toggle": true,
            "minimized": true,
            "defaultMinimized": true
        },
        "content": {
            "width": "fluid"
        },
        "toolbar": {
            "display": true,
            "width": "fluid",
            "breadcrumb": true
        },
        "sidebar": {
            "display": false,
            "toggle": true,
            "shown": false,
            "content": "general",
            "bgColor": "bg-info",
            "displayFooter": true,
            "displayFooterButton": true
        },
        "footer": {
            "width": "fluid"
        }
    },
    golyath: {
        "name": "Golyath",
        "apiHost": "https://api.golyath.com",
        "apiVersion": "v2",
        "apiClient": "rtRGF%6588IKJ*23HgtH!6",
        "version": "v4.0.0",
        "fullName": "Golyath :: ",
        "loader": {
            "display": true,
            "type": "default"
        },
        "scrollTop": {
            "display": false
        },
        "header": {
            "showSearch": false,
            "display": true,
            "width": "fluid",
            "fixed": {
                "desktop": true,
                "tabletAndMobile": true
            }
        },
        "megaMenu": {
            "display": false
        },
        "aside": {
            "display": true,
            "fixed": true,
            "menu": "main",
            "primaryDisplay": true,
            "secondaryDisplay": true,
            "secondaryDisplayAsHover": false,
            "toggle": true,
            "minimized": false,
            "defaultMinimized": false
        },
        "content": {
            "width": "fluid"
        },
        "toolbar": {
            "display": true,
            "width": "fluid",
            "breadcrumb": true
        },
        "sidebar": {
            "display": false,
            "toggle": true,
            "shown": false,
            "content": "general",
            "bgColor": "bg-info",
            "displayFooter": true,
            "displayFooterButton": true
        },
        "footer": {
            "width": "fluid"
        }
    },
    teamsports: {
        "name": "11teamsports",
        "apiHost": "https://api.11ts-group.com",
        "apiVersion": "v2",
        "apiClient": "rt45-+*23HgtH!6",
        "version": "v4.0.0",
        "fullName": "11teamsports :: ",
        "loader": {
            "display": true,
            "type": "default"
        },
        "scrollTop": {
            "display": false
        },
        "header": {
            "showSearch": false,
            "display": true,
            "width": "fluid",
            "fixed": {
                "desktop": true,
                "tabletAndMobile": true
            }
        },
        "megaMenu": {
            "display": false
        },
        "aside": {
            "display": true,
            "fixed": true,
            "menu": "main",
            "primaryDisplay": true,
            "secondaryDisplay": true,
            "secondaryDisplayAsHover": false,
            "toggle": true,
            "minimized": true,
            "defaultMinimized": true
        },
        "content": {
            "width": "fluid"
        },
        "toolbar": {
            "display": true,
            "width": "fluid",
            "breadcrumb": true
        },
        "sidebar": {
            "display": false,
            "toggle": true,
            "shown": false,
            "content": "general",
            "bgColor": "bg-info",
            "displayFooter": true,
            "displayFooterButton": true
        },
        "footer": {
            "width": "fluid"
        }
    },
    avd: {
        "name": "avd",
        "apiHost": "https://api.avd-desk.com",
        "apiVersion": "v2",
        "apiClient": "3HgtH!6rt45-+*2",
        "version": "v4.0.0",
        "fullName": "AvD Desk :: ",
        "loader": {
            "display": true,
            "type": "default"
        },
        "scrollTop": {
            "display": false
        },
        "header": {
            "showSearch": false,
            "display": true,
            "width": "fluid",
            "fixed": {
                "desktop": true,
                "tabletAndMobile": true
            }
        },
        "megaMenu": {
            "display": false
        },
        "aside": {
            "display": true,
            "fixed": true,
            "menu": "main",
            "primaryDisplay": true,
            "secondaryDisplay": true,
            "secondaryDisplayAsHover": false,
            "toggle": true,
            "minimized": false,
            "defaultMinimized": false
        },
        "content": {
            "width": "fluid"
        },
        "toolbar": {
            "display": true,
            "width": "fluid",
            "breadcrumb": true
        },
        "sidebar": {
            "display": false,
            "toggle": true,
            "shown": false,
            "content": "general",
            "bgColor": "bg-info",
            "displayFooter": true,
            "displayFooterButton": true
        },
        "footer": {
            "width": "fluid"
        }
    },
    tlgee: {
        "name": "tlgee",
        "apiHost": "https://api.tlgee.com",
        "apiVersion": "v2",
        "apiClient": "tH!6345-:Xxwiuefghoinde",
        "version": "v4.0.0",
        "fullName": "TLGEE :: ",
        "loader": {
            "display": true,
            "type": "default"
        },
        "scrollTop": {
            "display": false
        },
        "header": {
            "showSearch": false,
            "display": true,
            "width": "fluid",
            "fixed": {
                "desktop": true,
                "tabletAndMobile": true
            }
        },
        "megaMenu": {
            "display": false
        },
        "aside": {
            "display": true,
            "fixed": true,
            "menu": "main",
            "primaryDisplay": true,
            "secondaryDisplay": true,
            "secondaryDisplayAsHover": false,
            "toggle": true,
            "minimized": false,
            "defaultMinimized": false
        },
        "content": {
            "width": "fluid"
        },
        "toolbar": {
            "display": true,
            "width": "fluid",
            "breadcrumb": true
        },
        "sidebar": {
            "display": false,
            "toggle": true,
            "shown": false,
            "content": "general",
            "bgColor": "bg-info",
            "displayFooter": true,
            "displayFooterButton": true
        },
        "footer": {
            "width": "fluid"
        }
    },
    sanopoly: {
        "name": "sanopoly",
        "apiHost": "https://api.sanopoly.com",
        "apiVersion": "v2",
        "apiClient": "r3HgF%6!tRGKJ588ItH*26",
        "version": "v4.0.0",
        "fullName": "sanopoly :: ",
        "loader": {
            "display": true,
            "type": "default"
        },
        "scrollTop": {
            "display": false
        },
        "header": {
            "showSearch": false,
            "display": true,
            "width": "fluid",
            "fixed": {
                "desktop": true,
                "tabletAndMobile": true
            }
        },
        "megaMenu": {
            "display": false
        },
        "aside": {
            "display": true,
            "fixed": true,
            "menu": "main",
            "primaryDisplay": true,
            "secondaryDisplay": true,
            "secondaryDisplayAsHover": false,
            "toggle": true,
            "minimized": true,
            "defaultMinimized": true
        },
        "content": {
            "width": "fluid"
        },
        "toolbar": {
            "display": true,
            "width": "fluid",
            "breadcrumb": true
        },
        "sidebar": {
            "display": false,
            "toggle": true,
            "shown": false,
            "content": "general",
            "bgColor": "bg-info",
            "displayFooter": true,
            "displayFooterButton": true
        },
        "footer": {
            "width": "fluid"
        }
    },
}

export default exportTheme[theme];
