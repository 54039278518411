import apiService from "@/core/services/apiService";
import jwtService from "@/core/services/jwtService";
import {Actions, Mutations} from "@/store/enums/store.enums";
import {Module, Action, Mutation, MutationAction, VuexModule} from "vuex-module-decorators";

export interface User {
    id: number;
    name: string;
    email: string;
    data: object;
    modules: Array<object>;
    navigation: Array<object>;
}

export interface UserInfo {
    error: String;
    user: User;
    isAuthenticated: boolean;
    userFetched: boolean;
    fetchMessages: boolean;
    newMessages: Array<object>;
    userModules: Array<object>;
    userAllocations: Array<object>;
    userNavigation: Array<object>;
    userDashboardWidgets: Array<object>;
}

@Module
export default class AuthModule extends VuexModule implements UserInfo {
    error = '';
    user = {} as User;
    isAuthenticated = false;
    userFetched = false;
    fetchMessages = false;
    newMessages = [];
    lastMessages = [];
    userModules = [];
    userAllocations = [];
    userNavigation = [];
    userDashboardWidgets = [];

    get getUser(): User {
        return this.user;
    }

    get getUserId(): Number {

        if (this.userFetched && this.isAuthenticated) {
            return this.user.id;
        }

        return 0;
    }

    get isUserAuthenticated(): boolean {
        return this.isAuthenticated;
    }

    get isUserFetched(): boolean {
        return this.userFetched;
    }

    get getError(): String {
        return this.error;
    }

    get getUserModules(): Array<object> {
        return this.userModules;
    }

    get getUserAllocations(): Array<object> {
        return this.userAllocations;
    }

    get getUserAllocationCustomerId(): String {

        if (this.userAllocations.hasOwnProperty('customers') && this.userAllocations['customers'].length === 1) {

            return this.userAllocations['customers'][0]?.value || null;

        }

        return null;

    }

    get getUserAllocationWarehouseId(): String {

        if (this.userAllocations.hasOwnProperty('warehouses') && this.userAllocations['warehouses'].length === 1) {

            return this.userAllocations['warehouses'][0]?.value || null;

        }

        return null;

    }

    get getUserNavigation(): Array<object> {
        return this.userNavigation;
    }

    get getUserDashboardWidgets(): Array<object> {
        return this.userDashboardWidgets;
    }

    get getNewMessageCount(): number {
        return this.newMessages.length || 0;
    }

    @Mutation
    [Mutations.SET_ERROR](data: any): void {
        const {description, message} = data;
        this.error = message || description || 'unknownError';
    }

    @Mutation
    [Mutations.SET_EXCEPTION](exception: any): void {
        this.error = exception?.toString() || 'unknownException';
    }

    @Mutation
    [Mutations.SET_AUTH](data) {

        return new Promise<void>((resolve, reject) => {

            const {token, refreshToken} = data;

            try {

                jwtService.saveToken(token);
                jwtService.saveRefreshToken(refreshToken);

                this.isAuthenticated = true;
                this.fetchMessages = true;
                this.error = '';

                resolve();

            } catch (_error) {

                reject();

            }

        });

    }

    @Mutation
    [Mutations.CONFIRM_AUTH]() {
        this.isAuthenticated = true;
        this.fetchMessages = true;
        this.error = '';
    }

    @Mutation
    [Mutations.SET_USER](data) {
        this.user = data;
        this.userFetched = true;
        this.isAuthenticated = true;
        this.fetchMessages = true;
    }

    @Mutation
    [Mutations.SET_TOKEN](data) {

        return new Promise<void>((resolve, reject) => {

            const {token, refreshToken} = data;

            try {

                jwtService.saveToken(token);
                jwtService.saveRefreshToken(refreshToken);

                this.isAuthenticated = true;
                this.fetchMessages = true;
                resolve();

            } catch (_error) {

                reject();

            }

        });

    }

    @Mutation
    [Mutations.PURGE_AUTH]() {

        this.isAuthenticated = false;
        this.fetchMessages = false;
        this.userFetched = false;
        this.user = {} as User;
        this.error = '';

        this.newMessages = [];
        this.lastMessages = [];
        this.userModules = [];
        this.userAllocations = [];
        this.userNavigation = [];
        this.userDashboardWidgets = [];

        jwtService.destroyToken();
        jwtService.destroyRefreshToken();
        apiService.destroyAuthorization();

    }

    @Mutation
    [Mutations.SET_NEW_MESSAGES](newMessages) {

        const {unread, last} = newMessages;

        this.newMessages = unread || [];
        this.lastMessages = last || [];

    }

    @Mutation
    [Mutations.STOP_POLLING]() {
        this.fetchMessages = false;
    }

    @Mutation
    [Mutations.SET_USER_NAVIGATION](userNavigation: any[]) {
        this.userNavigation = userNavigation || [];
    }

    @Mutation
    [Mutations.SET_USER_DASHBOARD_WIDGETS](userDashboardWidgets: any[]) {
        this.userDashboardWidgets = userDashboardWidgets || [];
    }

    @Mutation
    [Mutations.SET_USER_MODULES](modules: any[]) {
        this.userModules = modules;
    }

    @Mutation
    [Mutations.SET_USER_ALLOCATIONS](allocations: any[]) {
        this.userAllocations = allocations;
    }

    @Action
    [Actions.LOGOUT]() {

        this.context.commit(Mutations.PURGE_AUTH);
        this.context.commit(Mutations.RESET_HISTORY);

        // 1000ms timeout to prevent refetch and authentication errors after purgeAuth //
        setTimeout(() => this.context.commit(Mutations.SET_RENDER_UPDATE), 1000);

    }

    @Action
    [Actions.RESOLVE_EMPTY]() {
        this.context.commit(Mutations.STOP_POLLING);
    }

    @Action
    [Actions.REGISTER_DONE]() {

        console.log('REGISTER_DONE');

    }

    @Action
    [Actions.UPDATE_PASSWORD_DONE]() {

        console.log('UPDATE_PASSWORD_DONE');

    }

    @Action
    [Actions.FORGOT_PASSWORD_DONE]() {

        console.log('FORGOT_PASSWORD_DONE');
        //this.context.commit(Mutations.SET_EXCEPTION, response);

    }

    @Action
    [Actions.LOGIN](credentials) {

        return new Promise<void>((resolve, reject) => {

            apiService.post("user/login", credentials).then(async ({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified || !data?.token) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // set JWT Token from result //
                await this.context.commit(Mutations.SET_AUTH, data);
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_EXCEPTION, response);
                reject();

            });

        });

    }

    @Action
    [Actions.FACTOR](params) {

        return new Promise<void>((resolve, reject) => {

            apiService.post("user/factor", params).then(async ({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                this.context.commit(Mutations.CONFIRM_AUTH);
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_EXCEPTION, response);
                reject();

            });

        });

    }

    @Action
    [Actions.LOGIN_TOKEN](credentials) {

        return new Promise<void>((resolve, reject) => {

            apiService.post("user/token", credentials).then(async ({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified || !data?.token) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // set JWT Token from result //
                await this.context.commit(Mutations.SET_AUTH, data);
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_EXCEPTION, response);
                reject();

            });

        });

    }

    @Action
    [Actions.REGISTER](credentials) {

        return new Promise<void>((resolve, reject) => {

            apiService.post("user/register", credentials).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {

                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();

                }

                const result = data?.result || {};
                this.context.dispatch(Actions.REGISTER_DONE, result).then(() => {
                    resolve();
                });

            }).catch((response) => {

                this.context.commit(Mutations.SET_EXCEPTION, response);
                reject();

            });
        });

    }

    @Action
    [Actions.FORGOT_PASSWORD](payload) {

        return new Promise<void>((resolve, reject) => {

            apiService.post("user/password", payload).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {

                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();

                }

                const result = data?.result || {};
                this.context.dispatch(Actions.FORGOT_PASSWORD_DONE, result).then(() => {
                    resolve();
                });

            }).catch((response) => {

                this.context.commit(Mutations.SET_EXCEPTION, response);
                reject();

            });

        });

    }

    @Action
    [Actions.CHECK_AUTH](to) {

        return new Promise<void>((resolve, reject) => {

                const {meta} = to;
                const target = meta.module || 'unknown';
                const right = meta.right || 'list';

                if (!target || typeof target !== 'string') {
                    this.context.dispatch(Actions.LOGOUT);
                    return reject();
                }

                // system always ok //
                if (target === 'system' || target === 'cms' || target === 'dashboard') {
                    return resolve();
                }

                // check for user related right //
                const user = this.context.getters.getUser;
                const modules = user.modules || [];

                for (const module of modules) {

                    const check = module.name || null;
                    const rights = module.rights || {};
                    if (check === target && rights.hasOwnProperty(right) && parseInt(rights[right]) === 1) {
                        return resolve();
                    }

                }

                reject();

            }
        );

    }

    @Action({rawError: true})
    [Actions.REFRESH_AUTH](payload) {

        return new Promise<void>((resolve, reject) => {

            apiService.put("refresh", payload).then(async ({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    await this.context.dispatch(Actions.LOGOUT);
                    return reject();
                }

                // set new token and refresh token //
                this.context.commit(Mutations.SET_TOKEN, data);

                // get fresh user data maybe module rights changed //
                await this.context.dispatch(Actions.FETCH_USER);

                // force new main render key to update navigation elements and .... //
                // disabled because of overlays are closed etc @ToDo check for sideffects
                //this.context.commit(Mutations.SET_RENDER_UPDATE);

                // everything ok //
                resolve();

            }).catch(() => {

                this.context.dispatch(Actions.LOGOUT);
                reject();

            });

        });

    }

    @Action
    // @Todo API Endpoint for allocations //
    [Actions.REFRESH_ALLOCATIONS]() {

        return new Promise<void>((resolve, reject) => {

            apiService.get("user/profile").then(async ({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified || !data?.result) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                const {result} = data;
                const {allocations} = result;

                await this.context.commit(Mutations.SET_USER_ALLOCATIONS, allocations);

                resolve();

            }).catch((response) => {

                this.context.dispatch(Actions.LOGOUT, response).then(() => {
                    reject();
                });

            });

        });

    }

    @Action
    [Actions.UPDATE_PASSWORD](payload) {

        return new Promise<void>((resolve, reject) => {

            apiService.put("user/account", payload).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {

                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();

                }

                const result = data?.result || {};
                this.context.dispatch(Actions.UPDATE_PASSWORD_DONE, result).then(() => {
                    resolve();
                });

            }).catch((response) => {

                this.context.commit(Mutations.SET_EXCEPTION, response);
                reject();

            });

        });

    }

    @Action
    [Actions.FETCH_NEW_MESSAGES]() {

        //@ ToDo - HeadsUp --> Same Promise with Dashboard Widget WIDGET_SYSTEM_NOTIFICATIONS //
        return new Promise<void>((resolve, reject) => {

            apiService.post("user/messages/", {}).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified || !data?.result) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return resolve();
                }

                this.context.commit(Mutations.SET_NEW_MESSAGES, data?.result);
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_EXCEPTION, response);
                reject();

            });

        });

    }

    @Action
    [Actions.CLEAR_NEW_MESSAGES]() {

        return new Promise<void>((resolve, reject) => {

            apiService.delete("user/messages").then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified || !data?.result) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                this.context.commit(Mutations.SET_NEW_MESSAGES, []);
                resolve();

            }).catch((response) => {
            });

        });

    }

    @Action
    [Actions.FETCH_USER]() {

        return new Promise<void>((resolve, reject) => {

            apiService.get("user/profile").then(async ({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified || !data?.result) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                const {result} = data;
                const {user, modules, navigation, widgets, allocations} = result;

                let moduleNavigation = <Array<object>>[];
                for (const subNavigation of navigation) {
                    if (subNavigation.hasOwnProperty('identifier') && subNavigation.hasOwnProperty('pages') && subNavigation['identifier'] === 'modules') {
                        moduleNavigation = moduleNavigation.concat(subNavigation['pages']);
                    }
                }

                this.context.commit(Mutations.SET_USER, result);
                this.context.commit(Mutations.SET_USER_NAVIGATION, navigation);
                this.context.commit(Mutations.SET_USER_DASHBOARD_WIDGETS, widgets);
                this.context.commit(Mutations.SET_USER_MODULES, modules);
                this.context.commit(Mutations.SET_USER_ALLOCATIONS, allocations);

                resolve();

            }).catch((response) => {

                this.context.dispatch(Actions.LOGOUT, response).then(() => {
                    reject();
                });

            });

        });

    }

}
