interface Loader {
    display: boolean;
    type: "default" | "spinner-message" | "spinner-logo";
}

interface ScrollTop {
    display: boolean;
}

interface Fixed {
    desktop: boolean;
    tabletAndMobile: boolean;
}

interface Header {
    display: boolean;
    width: "fixed" | "fluid";
    fixed: Fixed;
    showSearch: boolean;
}

interface MegaMenu {
    display: boolean;
}

interface Aside {
    display: boolean;
    fixed: boolean;
    menu: "main";
    primaryDisplay: boolean;
    secondaryDisplay: boolean;
    secondaryDisplayAsHover: boolean;
    toggle: boolean;
    minimized: boolean;
    defaultMinimized: boolean;
}

interface Content {
    width: "fixed" | "fluid";
}

interface Toolbar {
    display: boolean;
    width: "fixed" | "fluid";
    breadcrumb: boolean;
}

interface Sidebar {
    display: boolean;
    toggle: boolean;
    shown: boolean;
    content: "general" | "user" | "shop";
    bgColor: string;
    displayFooter: true;
    displayFooterButton: true;
}

interface Footer {
    width: "fixed" | "fluid";
}

export interface layoutConfigInterface {
    name: string;
    apiHost: string;
    apiVersion: string;
    apiClient: string,
    version: string;
    fullName: string;
    loader: Loader;
    scrollTop: ScrollTop;
    header: Header;
    megaMenu: MegaMenu;
    aside: Aside;
    content: Content;
    toolbar: Toolbar;
    sidebar: Sidebar;
    footer: Footer;
}

// module.exports does not contain a default export
export default class LayoutConfigInterfaceClass {
}
