import apiService from "@/core/services/apiService";

export type State = { classes: object };
import {Module, VuexModule, Mutation, Action} from "vuex-module-decorators";
import {Mutations} from "@/store/enums/store.enums";
import {OrdersActions, OrdersMutation} from "@/modules/orders/store/enums";

@Module
export default class Store extends VuexModule {
    renderKey = 1;

    listParams = {
        page: 1,
        results: 10,
        search: null,
        sort: [],
        filter: {}
    };

    listCache = [];
    listTotal = 0;
    listFilterOptions = {};

    optionsConfirmConflictTypes = [
        {value: 'backorderShort', label: 'Verfügbare Ware sofort versenden, Fehlmenge als neuen Auftrag anlegen'},
        {value: 'shortShipped', label: 'Verfügbare Ware sofort versenden, Fehlmenge stornieren'},
        {value: 'cancel', label: 'Auftrag komplett stornieren'},
    ];

    optionsModeOfTransportationTypes = [
        {value: 'carrier', label: 'Carrier'},
        {value: 'ltl', label: 'LTL'},
        {value: 'ftl', label: 'FTL'}
    ];

    optionsCarrierTypes = [
        {value: 'br', label: 'Best Rate'},
        {value: 'custom', label: 'Eigenes Label'},
        {value: 'ups', label: 'UPS'},
        {value: 'fedex', label: 'FedEx'},
        {value: 'usps', label: 'USPS'},
        {value: 'dhl', label: 'DHL'},
    ];

    optionsCarrierServiceTypes = [
        {value: 'standard', label: 'Standard'},
        {value: 'fastest', label: 'Fastest'},
        {value: 'eco', label: 'Economical'},
    ];

    optionsConfirmChangeTypes = [
        {value: 'customer', label: 'Customer Request'},
        {value: 'damaged', label: 'Damaged'},
        {value: 'stock', label: 'Not on stock'},
    ];

    get getOrdersRenderKey() {
        return 'orders-' + this.renderKey;
    }

    get getOrdersLastListResult() {
        return this.listCache;
    }

    get getOrdersLastFilterOptions() {
        return this.listFilterOptions;
    }

    get getOrdersLastListTotal() {
        return this.listTotal;
    }

    get getOptionsModeOfTransportationTypes() {
        return this.optionsModeOfTransportationTypes;
    }

    get getOptionsConfirmConflictTypes() {
        return this.optionsConfirmConflictTypes;
    }

    get getOptionsCarrierTypes() {
        return this.optionsCarrierTypes;
    }

    get getOptionsCarrierServiceTypes() {
        return this.optionsCarrierServiceTypes;
    }

    get getOptionsConfirmChangeTypes() {
        return this.optionsConfirmChangeTypes;
    }

    get getOrdersMappingFields() {

        return {
            referenceNumber: {
                required: true,
                label: 'orders.referenceNumber',
                mapping: null,
                unique: true,
                duplicates: 'skip',
                options: null,
                optionsMapped: {}
            },
            company: {
                required: false,
                label: 'orders.company',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            name: {
                required: true,
                label: 'common.name',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            addressLine1: {
                required: true,
                label: 'common.addressLine1',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            addressLine2: {
                required: false,
                label: 'common.addressLine2',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            postcode: {
                required: true,
                label: 'common.postcode',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            city: {
                required: true,
                label: 'common.city',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            region: {
                required: false,
                label: 'common.region',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            phone: {
                required: true,
                label: 'common.phone',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            email: {
                required: true,
                label: 'common.email',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            }
        }

    }

    @Mutation
    [OrdersMutation.SET_RENDER_UPDATE]() {
        this.renderKey++;
    }

    @Mutation
    [OrdersMutation.SET_LIST_RESULT]({listParams, listTotal, listCache, listFilterOptions}) {
        this.listParams = listParams;
        this.listCache = listCache;
        this.listFilterOptions = listFilterOptions;
        this.listTotal = listTotal;
    }

    @Action
    [OrdersActions.REFRESH_LIST]() {
        this.context.dispatch(OrdersActions.FETCH_LIST, this.listParams);
    }

    @Action
    [OrdersActions.FETCH_LIST](params) {

        return new Promise<void>((resolve, reject) => {

            apiService.post("orders/list", params).then((response) => {

                const data = response.data || null;

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results into storage for reactive update if needed //
                this.context.commit(OrdersMutation.SET_LIST_RESULT, {
                    listParams: params,
                    listTotal: data.results,
                    listCache: data.result,
                    listFilterOptions: data.filter
                });
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [OrdersActions.GET_ITEM](detailId) {

        return new Promise<void>((resolve, reject) => {

            apiService.get(`orders/get/${detailId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [OrdersActions.CREATE_ITEM](payload) {

        return new Promise((resolve, reject) => {

            const {data} = payload;

            apiService.post('orders/create/', data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // flag cached keep alive comp to render new //
                // disabled because of wizard needs to stay //
                //this.context.commit(OrdersMutation.SET_RENDER_UPDATE);

                // refresh list in background//
                this.context.dispatch(OrdersActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [OrdersActions.UPDATE_ITEM](payload) {

        return new Promise((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`orders/edit/${editId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(OrdersActions.REFRESH_LIST);

                // flag cached keep alive comp to render new //
                // @ToDo Notwendig??
                //this.context.commit(OrdersMutation.SET_RENDER_UPDATE);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [OrdersActions.UPDATE_STATE](payload) {

        return new Promise((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`orders/state/${editId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list //
                this.context.dispatch(OrdersActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [OrdersActions.CONFIRM_CONFLICT](payload) {

        return new Promise<void>((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`orders/confirm-conflict/${editId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(OrdersActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [OrdersActions.CONFIRM_PICKLIST](payload) {

        return new Promise<void>((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`orders/confirm-picklist/${editId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(OrdersActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [OrdersActions.IMPORT_ORDERS](payload) {

        return new Promise((resolve, reject) => {

            const {importer} = payload;

            apiService.put('orders/import/', importer).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(OrdersActions.REFRESH_LIST);

                // resolve //
                resolve(data.report);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [OrdersActions.DELETE_ITEM](editId) {

        return new Promise((resolve, reject) => {

            apiService.delete(`orders/delete/${editId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(OrdersActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [OrdersActions.UPLOAD_DATA](payload) {

        return new Promise<void>((resolve, reject) => {

            const {editId, files} = payload;

            apiService.post(`orders/upload/${editId}`, files).then((response) => {

                const data = response.data || null;

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [OrdersActions.DELETE_FILE](file) {

        return new Promise((resolve, reject) => {

            const {orderFileId} = file;

            apiService.delete(`orders/file/${orderFileId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(OrdersActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

}
