enum BlogActions {
    FETCH_LIST = "blogFetchList",
    GET_ITEM = "blogGetItem",
    CREATE_ITEM = "blogCreateItem",
    UPDATE_ITEM = "blogUpdateItem",
    DELETE_ITEM = "blogDeleteItem",
    REFRESH_LIST = "blogRefreshList",
}

enum BlogMutation {
    SET_RENDER_UPDATE = "blogListRenderNeeded",
    SET_LIST_RESULT = "blogListResult"
}

export {BlogActions, BlogMutation};
