import apiService from "@/core/services/apiService";

export type State = { classes: object };
import {Module, VuexModule, Mutation, Action} from "vuex-module-decorators";
import {Mutations} from "@/store/enums/store.enums";
import {ItemsActions, ItemsMutation} from "@/modules/items/store/enums";

@Module
export default class Store extends VuexModule {
    renderKey = 1;
    listParams = {
        page: 1,
        results: 10,
        search: null,
        sort: [],
        filter: {}
    };
    listCache = [];
    listFilterOptions = {};
    listTotal = 0;

    optionsSKUTypes = [
        {id: 1, value: '1', label: 'General SKU'},
        {id: 2, value: '2', label: 'Internal SKU'},
        {id: 3, value: '3', label: 'External SKU'},
        {id: 4, value: '4', label: 'Manufacturer SKU'},
        {id: 5, value: '5', label: 'Merchant SKU'},
        {id: 6, value: '6', label: 'UPC'},
        {id: 7, value: '7', label: 'EAN'},
        {id: 8, value: '8', label: 'ASIN'},
        {id: 9, value: '9', label: 'FNSKU'},
    ];

    optionsInventoryTypes = [
        {value: '1', label: 'First In First Out (FIFO)'},
        {value: '2', label: 'Last in First Out (LIFO)'},
        {value: '3', label: 'First Expired First Out (FEFO)'},
    ];

    optionsCycleCountTypes = [
        {value: '1', label: 'A'},
        {value: '2', label: 'B'},
        {value: '3', label: 'C'},
    ];

    optionsSerialNumberTrackingTypes = [
        {value: '1', label: 'Do not track by serial number'},
        {value: '2', label: 'Track by serial number'},
        {value: '3', label: 'Track by serial number and serial number must be unique'},
        {value: '4', label: 'Serial number required but does not have to be unique'},
        {value: '5', label: 'Serial number required and must be unique'},
    ];

    optionsExpirationDateTrackingTypes = [
        {value: '1', label: 'Do not track expiration date'},
        {value: '2', label: 'Track by expiration date'},
        {value: '3', label: 'Require expiration date tracking'},
    ];

    optionsLotNumberTrackingTypes = [
        {value: '1', label: 'Do not track lot number'},
        {value: '2', label: 'Track by lot number'},
        {value: '3', label: 'Require lot number tracking'},
    ];

    optionsCostNumberTrackingTypes = [
        {value: '1', label: 'Do not track cost'},
        {value: '2', label: 'Track by cost'},
        {value: '3', label: 'Require cost tracking'},
    ];

    optionsShipmentRequirementsTypes = [
        {value: '1', label: 'Luftpolsterfolie'},
        {value: '2', label: 'Luftpolsterfolienbeutel'},
        {value: '3', label: 'Styropor'},
        {value: '4', label: 'Schaumfolie'},
        {value: '5', label: 'Schaumfolienbeutel'},
        {value: '6', label: 'Stretchfolie'},
        {value: '7', label: 'Schlauchfolie'},
        {value: '8', label: 'Druckverschlussbeutel'},
        {value: '9', label: 'Vliesbeutel'},
        {value: '10', label: 'Flachbeutel'},
        {value: '11', label: 'Vakuumbeutel'},
        {value: '12', label: 'Polstermatten'},
        {value: '13', label: 'Packpapier'},
        {value: '14', label: 'Luftkissen'},
        {value: '15', label: 'Rollenwellpappe'},
        {value: '16', label: 'Kantenschutzleisten'},
        {value: '17', label: 'Gefache'},
    ];

    optionsMaterialFlagTypes = [
        {value: '0', label: 'Unspecified'},
        {value: '1', label: 'X'},
        {value: '2', label: 'RQ'},
    ];

    optionsHazardousPackagingGroups = [
        {value: '0', label: 'Unspecified'},
        {value: '1', label: 'I'},
        {value: '2', label: 'II'},
        {value: '3', label: 'III'},
    ];

    optionsPackagingTypes = [
        {value: "", label: "Unspecified"},
        {value: "each", label: "Each"},
        {value: "carton", label: "Carton"},
        {value: "inner-carton", label: "Inner carton"},
        {value: "master-carton", label: "Master carton"},
        {value: "pallet", label: "Pallet"},
        {value: "crate", label: "Crate"},
        {value: "roll", label: "Roll"},
        {value: "tote", label: "Tote"},
        {value: "bale", label: "Bale"},
        {value: "ream", label: "Ream"},
        {value: "drum", label: "Drum"},
        {value: "gallon-drum-15", label: "gallon drum 15"},
        {value: "gallon-drum-20", label: "gallon drum 20"},
        {value: "gallon-drum-30", label: "gallon drum 30"},
        {value: "gallon-drum-55", label: "gallon drum 55"},
        {value: "gallon-jug", label: "Gallon jug"},
        {value: "quart-jug", label: "Quart jug"},
        {value: "gallon-jug-5", label: "gallon jug 5"}
    ];

    get getItemsRenderKey() {
        return 'items-' + this.renderKey;
    }

    get getItemsLastListResult() {
        return this.listCache;
    }

    get getItemsLastListTotal() {
        return this.listTotal;
    }

    get getItemsLastFilterOptions() {
        return this.listFilterOptions;
    }

    get getOptionsSKUTypes() {
        return this.optionsSKUTypes;
    }

    get getOptionsInventoryTypes() {
        return this.optionsInventoryTypes;
    }

    get getOptionsCycleCountTypes() {
        return this.optionsCycleCountTypes;
    }

    get getOptionsSerialNumberTrackingTypes() {
        return this.optionsSerialNumberTrackingTypes;
    }

    get getOptionsExpirationDateTrackingTypes() {
        return this.optionsExpirationDateTrackingTypes;
    }

    get getOptionsLotNumberTrackingTypes() {
        return this.optionsLotNumberTrackingTypes;
    }

    get getOptionsCostNumberTrackingTypes() {
        return this.optionsCostNumberTrackingTypes;
    }

    get getOptionsShipmentRequirementsTypes() {
        return this.optionsShipmentRequirementsTypes;
    }

    get getOptionsMaterialFlagTypes() {
        return this.optionsMaterialFlagTypes;
    }

    get getOptionsHazardousPackagingGroups() {
        return this.optionsHazardousPackagingGroups;
    }

    get getOptionsPackagingTypes() {
        return this.optionsPackagingTypes;
    }

    get getItemsMappingFields() {

        const optionsSKUTypes = this.context.getters.getOptionsSKUTypes;

        return {
            generalSku: {
                required: true,
                label: 'items.primarySku',
                mapping: null,
                unique: false,
                duplicates: 'skip',
                options: optionsSKUTypes,
                optionsMulti: false,
                optionsMapped: {}
            },
            description: {
                required: true,
                label: 'common.description',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            sellPrice: {
                required: false,
                label: 'items.sellPrice',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            minInventory: {
                required: false,
                label: 'items.minInventory',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            maxInventory: {
                required: false,
                label: 'items.maxInventory',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            reorderQuantity: {
                required: false,
                label: 'items.reorderQuantity',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            minTemp: {
                required: false,
                label: 'items.minTemp',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            maxTemp: {
                required: false,
                label: 'items.maxTemp',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            internalSku: {
                required: false,
                label: 'items.internalSku',
                mapping: null,
                unique: false,
                duplicates: 'skip',
                options: null,
                optionsMulti: false,
                optionsMapped: {}
            },
        };

    }

    @Mutation
    [ItemsMutation.SET_RENDER_UPDATE]() {
        this.renderKey++;
    }

    @Mutation
    [ItemsMutation.SET_LIST_RESULT]({listParams, listTotal, listCache, listFilterOptions}) {
        this.listParams = listParams;
        this.listCache = listCache;
        this.listFilterOptions = listFilterOptions;
        this.listTotal = listTotal;
    }

    @Action
    [ItemsActions.REFRESH_LIST]() {
        this.context.dispatch(ItemsActions.FETCH_LIST, this.listParams);
    }

    @Action
    [ItemsActions.SEARCH_ITEMS](params) {

        return new Promise<void>((resolve, reject) => {

            apiService.post("items/search", params).then((response) => {

                const data = response.data || null;

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                resolve(data);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ItemsActions.FETCH_LIST](params) {

        return new Promise<void>((resolve, reject) => {

            apiService.post("items/list", params).then((response) => {

                const data = response.data || null;

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results into storage for reactive update if needed //
                this.context.commit(ItemsMutation.SET_LIST_RESULT, {
                    listParams: params,
                    listTotal: data.results,
                    listCache: data.result,
                    listFilterOptions: data.filter
                });

                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ItemsActions.GET_ITEM](detailId) {

        return new Promise<void>((resolve, reject) => {

            apiService.get(`items/get/${detailId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ItemsActions.CREATE_ITEM](payload) {

        return new Promise((resolve, reject) => {

            const {data} = payload;

            apiService.post('items/create/', data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // flag cached keep alive comp to render new //
                this.context.commit(ItemsMutation.SET_RENDER_UPDATE);

                // refresh list in background//
                this.context.dispatch(ItemsActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ItemsActions.UPDATE_ITEM](payload) {

        return new Promise((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`items/edit/${editId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(ItemsActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ItemsActions.GET_VARIANTS](payload) {

        return new Promise((resolve, reject) => {

            const {itemId} = payload;

            apiService.get(`items/variants/${itemId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ItemsActions.IMPORT_ITEMS](payload) {

        return new Promise((resolve, reject) => {

            const {importer} = payload;

            apiService.put('items/import/', importer).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(ItemsActions.REFRESH_LIST);

                // resolve //
                resolve(data.report);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ItemsActions.DELETE_ITEM](editId) {

        return new Promise((resolve, reject) => {

            apiService.delete(`items/delete/${editId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(ItemsActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

}
