enum ShipmentActions {
    FETCH_LIST = "shipmentFetchList",
    GET_ITEM = "shipmentGetItem",
    CREATE_ITEM = "shipmentCreateItem",
    DELETE_ITEM = "shipmentDeleteItem",
    REFRESH_LIST = "shipmentRefreshList",
    CONFIRM_SHIPMENT = "shipmentConfirmShipment",
    UPDATE_SHIPMENT = "shipmentUpdateShipment",
    UPLOAD_DATA = "shipmentUploadData",
    DELETE_FILE = "shipmentDeleteFile",
}

enum ShipmentMutations {
    SET_RENDER_UPDATE = "shipmentListRenderNeeded",
    SET_LIST_RESULT = "shipmentListResult"
}

export {ShipmentActions, ShipmentMutations};
