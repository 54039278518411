enum AddressbookActions {
    FETCH_LIST = "addressbookFetchList",
    GET_ITEM = "addressbookGetItem",
    CREATE_ITEM = "addressbookCreateItem",
    UPDATE_ITEM = "addressbookUpdateItem",
    DELETE_ITEM = "addressbookDeleteItem",
    REFRESH_LIST = "addressbookRefreshList",
}

enum AddressbookMutation {
    SET_RENDER_UPDATE = "addressbookListRenderNeeded",
    SET_LIST_RESULT = "addressbookListResult"
}

export {AddressbookActions, AddressbookMutation};
