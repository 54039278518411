enum ShopActions {
    FETCH_LIST = "shopFetchList",
    GET_ITEM = "shopGetItem",
    CREATE_ITEM = "shopCreateItem",
    UPDATE_ITEM = "shopUpdateItem",
    DELETE_ITEM = "shopDeleteItem",
    REFRESH_LIST = "shopRefreshList",
}

enum ShopMutation {
    SET_RENDER_UPDATE = "shopListRenderNeeded",
    SET_LIST_RESULT = "shopListResult"
}

export {ShopActions, ShopMutation};
