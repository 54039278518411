const ID_TOKEN_KEY = "vo-storage-desk-token" as string;
const ID_TOKEN_REFRESH_KEY = "vo-storage-desk-token-refresh" as string;

const jwtService = {
    saveToken: function (token: string) {
        return window.localStorage.setItem(ID_TOKEN_KEY, token);
    },
    saveRefreshToken: function (token: string) {
        return window.localStorage.setItem(ID_TOKEN_REFRESH_KEY, token);
    },
    getToken: function () {
        return window.localStorage.getItem(ID_TOKEN_KEY);
    },
    getRefreshToken: function () {
        return window.localStorage.getItem(ID_TOKEN_REFRESH_KEY);
    },
    destroyToken: function () {
        window.localStorage.removeItem(ID_TOKEN_KEY);
    },
    destroyRefreshToken: function () {
        window.localStorage.removeItem(ID_TOKEN_REFRESH_KEY);
    }
};

export default jwtService;
