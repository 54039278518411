enum AccountingActions {
    FETCH_LIST = "accountingFetchList",
    GET_ITEM = "accountingGetItem",
    CREATE_ITEM = "accountingCreateItem",
    UPDATE_ITEM = "accountingUpdateItem",
    DELETE_ITEM = "accountingDeleteItem",
    REFRESH_LIST = "accountingRefreshList",
}

enum AccountingMutation {
    SET_RENDER_UPDATE = "accountingListRenderNeeded",
    SET_LIST_RESULT = "accountingListResult"
}

export {AccountingActions, AccountingMutation};
