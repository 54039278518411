export type State = { classes: object };
import {Module, VuexModule, Mutation, Action} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/store.enums";

export interface HistoryInfo {
    stack: object;
    last: object;
    isEditing: boolean;
    isLoading: boolean;
    activeModule: String;
    activeModules: Array<object>;
    moduleLastRoute: object;
}

@Module
export default class HistoryModule extends VuexModule implements HistoryInfo {
    stack = [] as any;
    last = {};
    isEditing = false;
    isLoading = false;
    activeModules = [];
    moduleLastRoute = {};
    activeModule = 'dashboard';

    get getIsEditing(): boolean {
        return this.isEditing;
    }

    get getIsLoading(): boolean {
        return this.isLoading;
    }

    get getStack(): object {
        return this.stack;
    }

    get getActiveModule(): String {
        return this.activeModule;
    }

    get getActiveModules(): Array<object> {
        return this.activeModules;
    }

    get getLastRoute(): object {
        return this.stack.at(-1);
    }

    get getModuleLastRoute(): object {
        return this.moduleLastRoute || {};
    }

    @Mutation
    [Mutations.RESET_HISTORY]() {

        this.stack = [] as any;
        this.last = {};
        this.isEditing = false;
        this.isLoading = false;
        this.activeModules = [];
        this.moduleLastRoute = {};
        this.activeModule = 'dashboard';

    }

    @Mutation
    [Mutations.SET_ROUTE_HISTORY](payload: object) {
        this.stack.push(payload);
    }

    @Mutation
    [Mutations.SET_MODULE_LAST_ROUTE](payload) {

        const {module, route} = payload;
        if (!this.moduleLastRoute.hasOwnProperty(module)) {
            this.moduleLastRoute[module] = {};
        }

        this.moduleLastRoute[module] = route;


    }

    @Mutation
    [Mutations.SET_ACTIVE_MODULE](activeModule) {
        this.activeModule = activeModule;
    }

    @Mutation
    [Mutations.COMMIT_USER_LAUNCHED_MODULE](activeModules) {
        this.activeModules = activeModules;
    }

    @Action
    [Actions.CLOSE_LAUNCHED_MODULE](moduleIdentifier) {

        // set module as active //
        this.context.dispatch(Actions.SET_USER_LAUNCHED_MODULE, 'dashboard');

        // get list of current active modules //
        let whatever = this.context.getters.getActiveModules;
        let activeModules = whatever.filter(item => moduleIdentifier !== item.identifier);
        this.context.commit(Mutations.COMMIT_USER_LAUNCHED_MODULE, activeModules);

    }

    @Action
    [Actions.SET_USER_LAUNCHED_MODULE](moduleIdentifier) {

        // set module as active //
        this.context.commit(Mutations.SET_ACTIVE_MODULE, moduleIdentifier);

        let launchedModule = null;
        const userNavigation = this.context.getters.getUserNavigation;
        for (const navigation of userNavigation) {

            if (navigation.pages) {

                for (const page of navigation.pages) {

                    if (page.identifier === moduleIdentifier) {
                        launchedModule = page;
                        break;
                    }

                }

            }

        }

        if (!launchedModule) {
            return;
        }

        // get list of current active modules //
        let whatever = this.context.getters.getActiveModules;
        let activeModules = [...whatever];

        let isNew = true;
        for (const item of whatever) {

            if (launchedModule.identifier === item.identifier) {
                isNew = false;
                break;
            }

        }

        if (isNew) {
            activeModules.push(launchedModule);
            this.context.commit(Mutations.COMMIT_USER_LAUNCHED_MODULE, activeModules);
        }

    }

    @Action
    [Actions.ADD_ROUTE_HISTORY](payload) {

        // set route //
        this.context.commit(Mutations.SET_ROUTE_HISTORY, payload);

        const prevModule = this.context.getters.getActiveModule;
        const {module} = payload.meta;
        if (module) {

            // set launched module /
            this.context.dispatch(Actions.SET_USER_LAUNCHED_MODULE, module);

            // set module last path //
            this.context.commit(Mutations.SET_MODULE_LAST_ROUTE, {module: module, route: payload});

        }

        if (prevModule && prevModule !== module) {
            //this.context.dispatch(Actions.ADD_BODY_ATTRIBUTE, {qualifiedName: "data-vo-aside-minimize", value: "off"});
        }

    }

    @Mutation
    [Mutations.SET_IS_EDITING](isEditing: boolean) {
        this.isEditing = isEditing;
    }

    @Mutation
    [Mutations.SET_IS_LOADING](isLoading: boolean) {
        this.isLoading = isLoading;
    }

    @Action
    [Actions.IS_EDITING](isEditing: boolean) {
        this.context.commit(Mutations.SET_IS_EDITING, isEditing)
    };

    @Action
    [Actions.IS_LOADING](isLoading: boolean) {
        this.context.commit(Mutations.SET_IS_LOADING, isLoading)
    };


}
