enum ReviewsActions {
    FETCH_LIST = "reviewsFetchList",
    GET_ITEM = "reviewsGetItem",
    CREATE_ITEM = "reviewsCreateItem",
    UPDATE_ITEM = "reviewsUpdateItem",
    DELETE_ITEM = "reviewsDeleteItem",
    REFRESH_LIST = "reviewsRefreshList",
}

enum ReviewsMutation {
    SET_RENDER_UPDATE = "reviewsListRenderNeeded",
    SET_LIST_RESULT = "reviewsListResult"
}

export {ReviewsActions, ReviewsMutation};
