import apiService from "@/core/services/apiService";

export type State = { classes: object };
import {Module, VuexModule, Mutation, Action} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/store.enums";
import {UsersActions, UsersMutation} from "@/modules/users/store/enums";
import store from "@/store";

@Module
export default class Store extends VuexModule {
    renderKey = 1;
    listParams = {
        page: 1,
        results: 10,
        search: null,
        sort: [],
        filter: {}
    };
    listCache = [];
    listTotal = 0;

    get getUsersRenderKey() {
        return 'users-' + this.renderKey;
    }

    get getUsersLastListResult() {
        return this.listCache;
    }

    get getUsersLastListTotal() {
        return this.listTotal;
    }

    @Mutation
    [UsersMutation.SET_RENDER_UPDATE]() {
        this.renderKey++;
    }

    @Mutation
    [UsersMutation.SET_LIST_RESULT]({listParams, listTotal, listCache}) {
        this.listParams = listParams;
        this.listCache = listCache;
        this.listTotal = listTotal;
    }

    @Action
    [UsersActions.REFRESH_LIST]() {
        this.context.dispatch(UsersActions.FETCH_LIST, this.listParams);
    }

    @Action
    [UsersActions.FETCH_LIST](params) {

        return new Promise<void>((resolve, reject) => {

            apiService.post("user/list", params).then((response) => {

                const data = response.data || null;

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results into storage for reactive update if needed //
                this.context.commit(UsersMutation.SET_LIST_RESULT, {
                    listParams: params,
                    listTotal: data.results,
                    listCache: data.result
                });
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [UsersActions.GET_ITEM](detailId) {

        return new Promise<void>((resolve, reject) => {

            apiService.get(`user/get/${detailId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

        @Action
    [UsersActions.GET_ROLES]( role:string ) {

        return new Promise<void>((resolve, reject) => {

            apiService.get(`user/roles/${role}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [UsersActions.GET_ACCOUNT]() {

        return new Promise<void>((resolve, reject) => {

            apiService.get('user/account').then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [UsersActions.UPDATE_ACCOUNT](payload) {

        return new Promise<void>((resolve, reject) => {

            const {data} = payload;

            apiService.put('user/account', data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results to dispatcher without mutation //
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [UsersActions.PASSWORD_RESET](payload) {

        return new Promise<void>((resolve, reject) => {

            apiService.put('user/resetlogin', payload).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results to dispatcher without mutation //
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [UsersActions.CREATE_ITEM](payload) {

        return new Promise((resolve, reject) => {

            const {data} = payload;

            apiService.post('user/create/', data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // flag cached keep alive comp to render new //
                this.context.commit(UsersMutation.SET_RENDER_UPDATE);

                // refresh list in background//
                this.context.dispatch(UsersActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [UsersActions.UPDATE_ITEM](payload) {

        return new Promise((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`user/edit/${editId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(UsersActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [UsersActions.DELETE_ITEM](editId) {

        return new Promise((resolve, reject) => {

            apiService.delete(`user/delete/${editId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(UsersActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [UsersActions.UPDATE_RIGHTS](payload) {

        return new Promise<void>((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`user/rights/${editId}`, data).then(async ({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh current user profile to activate own changes //
                if (parseInt(editId) === parseInt(store.getters.getUserId)) {

                    await this.context.dispatch(Actions.FETCH_USER);

                    // force new main render key //
                    this.context.commit(Mutations.SET_RENDER_UPDATE);

                }

                // return results to dispatcher without mutation //
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [UsersActions.UPDATE_ALLOCATIONS](payload) {

        return new Promise<void>((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`user/allocations/${editId}`, data).then(async ({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

}
