enum ContentManagementActions {
    FETCH_LIST = "ContentManagementFetchList",
    GET_ITEM = "ContentManagementGetItem",
    CREATE_ITEM = "ContentManagementCreateItem",
    UPDATE_ITEM = "ContentManagementUpdateItem",
    DELETE_ITEM = "ContentManagementDeleteItem",
    REFRESH_LIST = "ContentManagementRefreshList",
}

enum ContentManagementMutation {
    SET_RENDER_UPDATE = "ContentManagementListRenderNeeded",
    SET_LIST_RESULT = "ContentManagementListResult"
}

export {ContentManagementActions, ContentManagementMutation};
