enum SystemActions {
    GET_SETTINGS = "systemFetchSettings",
    UPDATE_SETTINGS = "systemUpdateSettings",
    CREATE_ROLE = "systemCreateRole",
    DELETE_ROLE = "systemDeleteRole",
    OAUTH_AMAZON = 'systemSetAmazonToken',
    CHECK_ROLE_CONSTRAINTS = 'systemCheckRoleConstraints',
    REFRESH_MAILS = 'systemRefreshMails',
    FETCH_MAILS = 'systemFetchMails',
    CREATE_MAIL = 'systemCreateMail',
    UPDATE_MAIL = 'systemUpdateMail',
    DELETE_MAIL = 'systemDeleteMail',
    REFRESH_TRANSLATIONS = 'systemRefreshTranslations',
    FETCH_TRANSLATIONS = 'systemFetchTranslations',
    UPDATE_TRANSLATION = 'systemUpdateTranslation',
    CREATE_TRANSLATION = 'systemCreateTranslation',
    DELETE_TRANSLATION = 'systemDeleteTranslation',
}

enum SystemMutation {
    SET_RENDER_UPDATE = "systemListRenderNeeded",
    SET_LIST_RESULT = "systemListResult",
    SET_MAIL_LIST_RESULT = "systemMailListResult",
    REFRESH_MAIL_LIST = "systemRefreshMailList",
    SET_TRANSLATIONS_LIST_RESULT = "systemTranslationsListResult",
    REFRESH_TRANSLATIONS_LIST = "systemRefreshTranslationsList",
}

export {SystemActions, SystemMutation};
