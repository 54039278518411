import apiService from "@/core/services/apiService";

export type State = { classes: object };
import {Module, VuexModule, Mutation, Action} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/store.enums";
import {WarehousesActions, WarehousesMutation} from "@/modules/warehouses/store/enums";
import {AxiosResponse} from "axios";

@Module
export default class Store extends VuexModule {
    renderKey = 1;
    warehouseData = {
        accounts: {},
        base: {},
        settings: {}
    };

    listParams = {
        page: 1,
        results: 10,
        search: null,
        sort: [],
        filter: {}
    };
    listCache = [];
    listTotal = 0;

    locationsParams = {};
    locationsCache = [];
    locationsTotal = 0;
    locationsFilterOptions = {};

    packagingParams = {};
    packagingCache = [];
    packagingTotal = 0;
    packagingFilterOptions = {};

    get getWarehouseBaseData() {
        return this.warehouseData.base;
    }

    get getWarehouseSettingsData() {
        return this.warehouseData.settings;
    }

    get getWarehouseAccountData() {
        return this.warehouseData.accounts;
    }

    get getWarehouseId() {

        return this.warehouseData.base.hasOwnProperty('id') ? this.warehouseData.base['id'] : 0;
    }

    get getWarehouseName() {

        return this.warehouseData.base.hasOwnProperty('locationName') ? this.warehouseData.base['locationName'] : '';
    }

    get getLocationsMappingFields() {

        const locationIdentifier = this.context.getters.getWarehouseLocationIdentifiers;
        const locationBilling = this.context.getters.getWarehouseSpecializedLocationBilling;

        return {
            locationId: {
                required: true,
                label: 'warehouses.locationId',
                mapping: null,
                unique: true,
                duplicates: 'skip',
                options: null,
                optionsMapped: {}
            },
            locationIdentifierId: {
                alias: 'locationIdentifier',
                required: true,
                label: 'warehouses.locationIdentifier',
                mapping: null,
                unique: false,
                duplicates: null,
                options: locationIdentifier,
                optionsMapped: {}
            },
            locationBillingId: {
                required: false,
                label: 'warehouses.locationBilling',
                mapping: null,
                unique: false,
                duplicates: null,
                options: locationBilling,
                optionsMapped: {}
            },
            allocationPriority: {
                required: false,
                label: 'warehouses.allocationPriority',
                mapping: null,
                unique: false,
                duplicates: null
            },
            length: {
                required: false,
                label: 'common.length',
                mapping: null,
                unique: false,
                duplicates: null
            },
            width: {
                required: false,
                label: 'common.width',
                mapping: null,
                unique: false,
                duplicates: null
            },
            height: {
                required: false,
                label: 'common.height',
                mapping: null,
                unique: false,
                duplicates: null
            },
            maxWeight: {
                required: false,
                label: 'common.maxWeight',
                mapping: null,
                unique: false,
                duplicates: null
            },
            minTemp: {
                required: false,
                label: 'common.minTemp',
                mapping: null,
                unique: false,
                duplicates: null
            },
            maxTemp: {
                required: false,
                label: 'common.maxTemp',
                mapping: null,
                unique: false,
                duplicates: null
            },
            description: {
                required: false,
                label: 'common.description',
                mapping: null,
                unique: false,
                duplicates: null
            }
        };

    }

    get getWarehouseLocationIdentifiers() {

        return this.warehouseData.settings.hasOwnProperty('locationIdentifiers') ? this.warehouseData.settings['locationIdentifiers'] : [];
    }

    get getWarehouseSpecializedLocationBilling() {

        return this.warehouseData.settings.hasOwnProperty('specializedLocationBilling') ? this.warehouseData.settings['specializedLocationBilling'] : [];
    }

    get getWarehousesRenderKey() {
        return 'warehouses-' + this.renderKey;
    }

    get getWarehousesLastListResult() {
        return this.listCache;
    }

    get getWarehousesLastListTotal() {
        return this.listTotal;
    }

    get getWarehousesLocationsLastListResult() {
        return this.locationsCache;
    }

    get getWarehousesLocationsLastListTotal() {
        return this.locationsTotal;
    }

    get getWarehousesLocationsLastFilterOptions() {
        return this.locationsFilterOptions;
    }

    get getWarehousesPackagingLastListResult() {
        return this.packagingCache;
    }

    get getWarehousesPackagingLastListTotal() {
        return this.packagingTotal;
    }

    get getWarehousesPackagingLastFilterOptions() {
        return this.packagingFilterOptions;
    }

    @Mutation
    [WarehousesMutation.SET_RENDER_UPDATE]() {
        this.renderKey++;
    }

    @Mutation
    [WarehousesMutation.SET_WAREHOUSE_RESULT](data) {
        this.warehouseData = data;
    }

    @Mutation
    [WarehousesMutation.SET_LIST_RESULT]({params, total, cache}) {
        this.listParams = params;
        this.listCache = cache;
        this.listTotal = total;
    }

    @Mutation
    [WarehousesMutation.SET_LOCATIONS_RESULT]({params, total, cache}) {
        this.locationsParams = params;
        this.locationsCache = cache;
        this.locationsTotal = total;
    }

    @Mutation
    [WarehousesMutation.SET_PACKAGING_RESULT]({params, total, cache}) {
        this.packagingParams = params;
        this.packagingCache = cache;
        this.packagingTotal = total;
    }

    @Action
    [WarehousesActions.REFRESH_LIST]() {
        this.context.dispatch(WarehousesActions.FETCH_LIST, this.listParams);
    }

    @Action
    [WarehousesActions.FETCH_LIST](params) {

        return new Promise<void>((resolve, reject) => {

            apiService.post("warehouses/list", params).then((response) => {

                const data = response.data || null;

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results into storage for reactive update if needed //
                this.context.commit(WarehousesMutation.SET_LIST_RESULT, {
                    params: params,
                    total: data.results,
                    cache: data.result
                });
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [WarehousesActions.GET_ITEM](detailId) {

        return new Promise<void>((resolve, reject) => {

            apiService.get(`warehouses/get/${detailId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return and dispatch last called result //
                this.context.commit(WarehousesMutation.SET_WAREHOUSE_RESULT, data.result);

                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [WarehousesActions.CREATE_ITEM](payload) {

        return new Promise((resolve, reject) => {

            const {data} = payload;

            apiService.post('warehouses/create/', data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // flag cached keep alive comp to render new //
                this.context.commit(WarehousesMutation.SET_RENDER_UPDATE);

                // refresh list in background//
                this.context.dispatch(WarehousesActions.REFRESH_LIST);

                // refresh user customer allocations //
                this.context.dispatch(Actions.REFRESH_ALLOCATIONS);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [WarehousesActions.UPDATE_ITEM](payload) {

        return new Promise((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`warehouses/edit/${editId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(WarehousesActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [WarehousesActions.CHECK_DATA_CONSTRAINTS](payload) {

        return new Promise((resolve, reject) => {

            const {warehouseId, data} = payload;

            apiService.put(`warehouses/constraints/${warehouseId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [WarehousesActions.DELETE_ITEM](editId) {

        return new Promise((resolve, reject) => {

            apiService.delete(`warehouses/delete/${editId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(WarehousesActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [WarehousesActions.UPDATE_ACCOUNTS](payload) {

        return new Promise<void>((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`warehouses/accounts/${editId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [WarehousesActions.UPDATE_SETTINGS](payload) {

        return new Promise<void>((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`warehouses/settings/${editId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh data from warehouse //
                this.context.dispatch(WarehousesActions.GET_ITEM, editId);

                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    /* LOCATIONS */
    @Action
    [WarehousesActions.REFRESH_LOCATIONS]() {
        this.context.dispatch(WarehousesActions.FETCH_LOCATIONS, this.locationsParams);
    }

    @Action
    [WarehousesActions.FETCH_LOCATIONS](params) {

        return new Promise<void>((resolve, reject) => {

            apiService.post(`warehouses/locations`, params).then((response: AxiosResponse) => {

                const data = response.data || null;

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results into storage for reactive update if needed //
                this.context.commit(WarehousesMutation.SET_LOCATIONS_RESULT, {
                    params: params,
                    total: data.results,
                    cache: data.result
                });

                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [WarehousesActions.SEARCH_LOCATIONS](params) {

        return new Promise((resolve, reject) => {

            apiService.post(`warehouses/locations`, params).then((response: AxiosResponse) => {

                const data = response.data || null;

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                resolve(data);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [WarehousesActions.GET_LOCATION](detailId) {

        return new Promise<void>((resolve, reject) => {

            apiService.get(`warehouses/location/${detailId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return result //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [WarehousesActions.CREATE_LOCATION](payload) {

        return new Promise((resolve, reject) => {

            const {data} = payload;

            apiService.post('warehouses/location/', data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // flag cached keep alive comp to render new //
                this.context.commit(WarehousesMutation.SET_RENDER_UPDATE);

                // refresh list in background//
                this.context.dispatch(WarehousesActions.REFRESH_LOCATIONS);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [WarehousesActions.UPDATE_LOCATION](payload) {

        return new Promise((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`warehouses/location/${editId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(WarehousesActions.REFRESH_LOCATIONS);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [WarehousesActions.IMPORT_LOCATIONS](payload) {

        return new Promise((resolve, reject) => {

            const {warehouseId, importer} = payload;

            apiService.put(`warehouses/import/${warehouseId}`, importer).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(WarehousesActions.REFRESH_LOCATIONS);

                // resolve //
                resolve(data.report);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [WarehousesActions.DELETE_LOCATION](editId) {

        return new Promise((resolve, reject) => {

            apiService.delete(`warehouses/location/${editId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(WarehousesActions.REFRESH_LOCATIONS);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    /* PACKAGING */
    @Action
    [WarehousesActions.REFRESH_PACKAGINGS]() {
        this.context.dispatch(WarehousesActions.FETCH_PACKAGINGS, this.packagingParams);
    }

    @Action
    [WarehousesActions.FETCH_PACKAGINGS](params) {

        return new Promise<void>((resolve, reject) => {

            apiService.post(`warehouses/packagings`, params).then((response: AxiosResponse) => {

                const data = response.data || null;

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results into storage for reactive update if needed //
                this.context.commit(WarehousesMutation.SET_PACKAGING_RESULT, {
                    params: params,
                    total: data.results,
                    cache: data.result
                });

                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [WarehousesActions.SEARCH_PACKAGINGS](params) {

        return new Promise((resolve, reject) => {

            apiService.post(`warehouses/packages`, params).then((response: AxiosResponse) => {

                const data = response.data || null;

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                resolve(data);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [WarehousesActions.GET_PACKAGING](detailId) {

        return new Promise<void>((resolve, reject) => {

            apiService.get(`warehouses/packaging/${detailId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return result //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [WarehousesActions.CREATE_PACKAGING](payload) {

        return new Promise((resolve, reject) => {

            const {data} = payload;

            apiService.post('warehouses/packaging', data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // flag cached keep alive comp to render new //
                this.context.commit(WarehousesMutation.SET_RENDER_UPDATE);

                // refresh list in background//
                this.context.dispatch(WarehousesActions.REFRESH_PACKAGINGS);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [WarehousesActions.UPDATE_PACKAGING](payload) {

        return new Promise((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`warehouses/packaging/${editId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(WarehousesActions.REFRESH_PACKAGINGS);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [WarehousesActions.IMPORT_PACKAGINGS](payload) {

        return new Promise((resolve, reject) => {

            const {warehouseId, importer} = payload;

            apiService.put(`warehouses/import/${warehouseId}`, importer).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(WarehousesActions.REFRESH_PACKAGINGS);

                // resolve //
                resolve(data.report);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [WarehousesActions.DELETE_PACKAGING](editId) {

        return new Promise((resolve, reject) => {

            apiService.delete(`warehouses/packaging/${editId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(WarehousesActions.REFRESH_PACKAGINGS);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

}
