enum OrdersActions {
    FETCH_LIST = "ordersFetchList",
    GET_ITEM = "ordersGetItem",
    CREATE_ITEM = "ordersCreateItem",
    UPDATE_ITEM = "ordersUpdateItem",
    DELETE_ITEM = "ordersDeleteItem",
    REFRESH_LIST = "ordersRefreshList",
    CONFIRM_PICKLIST = "ordersConfirmPicklist",
    UPDATE_STATE = "ordersUpdateState",
    CONFIRM_CONFLICT = "ordersConfirmConflict",
    IMPORT_ORDERS = "ordersImport",
    UPLOAD_DATA = "ordersUploadData",
    DELETE_FILE = "ordersDeleteFile",
}

enum OrdersMutation {
    SET_RENDER_UPDATE = "ordersListRenderNeeded",
    SET_LIST_RESULT = "ordersListResult"
}

export {OrdersActions, OrdersMutation};
