export type State = { classes: object };

import apiService from "@/core/services/apiService";
import {Module, VuexModule, Mutation, Action} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/store.enums";
import {SystemActions, SystemMutation} from "@/modules/system/store/enums";

@Module
export default class Store extends VuexModule {
    renderKey = 1;

    mailListParams = {
        page: 1,
        results: 10,
        search: null,
        sort: [],
        filter: {}
    };

    mailListCache = [];
    mailListTotal = 0;
    mailListFilterOptions = {};

    translationsListParams = {
        page: 1,
        results: 10,
        search: null,
        sort: [],
        filter: {}
    };

    translationsListCache = [];
    translationsListTotal = 0;
    translationsListFilterOptions = {};

    get getSystemRenderKey() {
        return 'system-' + this.renderKey;
    }

    get getSystemLastMailListResult() {
        return this.mailListCache;
    }

    get getSystemLastMailFilterOptions() {
        return this.mailListFilterOptions;
    }

    get getSystemsLastMailListTotal() {
        return this.mailListTotal;
    }

    get getSystemLastTranslationListResult() {
        return this.translationsListCache;
    }

    get getSystemLastTranslationFilterOptions() {
        return this.translationsListFilterOptions;
    }

    get getSystemsLastTranslationListTotal() {
        return this.translationsListTotal;
    }

    @Mutation
    [SystemMutation.SET_RENDER_UPDATE]() {
        this.renderKey++;
    }

    @Mutation
    [SystemMutation.SET_MAIL_LIST_RESULT]({listParams, listTotal, listCache, listFilterOptions}) {
        this.mailListParams = listParams;
        this.mailListCache = listCache;
        this.mailListFilterOptions = listFilterOptions;
        this.mailListTotal = listTotal;
    }

    @Action
    [SystemMutation.REFRESH_MAIL_LIST]() {
        this.context.dispatch(SystemActions.FETCH_MAILS, this.mailListParams);
    }

    @Mutation
    [SystemMutation.SET_TRANSLATIONS_LIST_RESULT]({listParams, listTotal, listCache, listFilterOptions}) {
        this.translationsListParams = listParams;
        this.translationsListCache = listCache;
        this.translationsListFilterOptions = listFilterOptions;
        this.translationsListTotal = listTotal;
    }

    @Action
    [SystemMutation.REFRESH_TRANSLATIONS_LIST]() {
        this.context.dispatch(SystemActions.FETCH_TRANSLATIONS, this.translationsListParams);
    }

    @Action
    [SystemActions.GET_SETTINGS]() {

        return new Promise<void>((resolve, reject) => {

            apiService.get('system/settings').then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [SystemActions.CHECK_ROLE_CONSTRAINTS](roleId) {

        return new Promise((resolve, reject) => {

            apiService.get(`system/role-constraints/${roleId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [SystemActions.CREATE_ROLE](payload) {

        return new Promise((resolve, reject) => {

            apiService.post('system/role-create/', payload).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [SystemActions.DELETE_ROLE](payload) {

        return new Promise((resolve, reject) => {

            const {roleId} = payload;
            apiService.put(`system/role-delete/${roleId}`, payload).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [SystemActions.UPDATE_SETTINGS](payload) {

        return new Promise<void>((resolve, reject) => {

            apiService.put('system/patch', payload).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [SystemActions.OAUTH_AMAZON](payload) {

        return new Promise<void>((resolve, reject) => {

            apiService.post('licenses/oauth-amazon', payload).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [SystemActions.REFRESH_MAILS]() {
        this.context.dispatch(SystemActions.FETCH_MAILS, this.mailListParams);
    }

    @Action
    [SystemActions.FETCH_MAILS](params) {

        return new Promise<void>((resolve, reject) => {

            apiService.post("system/mails-list", params).then((response) => {

                const data = response.data || null;

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results into storage for reactive update if needed //
                this.context.commit(SystemMutation.SET_MAIL_LIST_RESULT, {
                    listParams: params,
                    listTotal: data.results,
                    listCache: data.result,
                    listFilterOptions: data.filter
                });
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [SystemActions.CREATE_MAIL](payload) {

        return new Promise((resolve, reject) => {

            const {data} = payload;

            apiService.post('system/mails-create/', data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                resolve(true);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [SystemActions.UPDATE_MAIL](payload) {

        return new Promise((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`system/mails-edit/${editId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                resolve(true);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [SystemActions.DELETE_MAIL](editId) {

        return new Promise((resolve, reject) => {

            apiService.delete(`system/mails-delete/${editId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                resolve(true);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [SystemActions.REFRESH_TRANSLATIONS]() {
        this.context.dispatch(SystemActions.FETCH_TRANSLATIONS, this.translationsListParams);
    }

    @Action
    [SystemActions.FETCH_TRANSLATIONS](params) {

        return new Promise<void>((resolve, reject) => {

            apiService.post("system/translations-list", params).then((response) => {

                const data = response.data || null;

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results into storage for reactive update if needed //
                this.context.commit(SystemMutation.SET_TRANSLATIONS_LIST_RESULT, {
                    listParams: params,
                    listTotal: data.results,
                    listCache: data.result,
                    listFilterOptions: data.filter
                });
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [SystemActions.CREATE_TRANSLATION](payload) {

        return new Promise((resolve, reject) => {

            const {data} = payload;

            apiService.post('system/translations-create/', data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                resolve(true);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [SystemActions.UPDATE_TRANSLATION](payload) {

        return new Promise((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`system/translations-edit/${editId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                resolve(true);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [SystemActions.DELETE_TRANSLATION](editId) {

        return new Promise((resolve, reject) => {

            apiService.delete(`system/translations-delete/${editId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                resolve(true);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

}
