enum UsersActions {
    FETCH_LIST = "usersFetchList",
    GET_ITEM = "usersGetItem",
    GET_ROLES = "usersGetRoles",
    CREATE_ITEM = "usersCreateItem",
    UPDATE_ITEM = "usersUpdateItem",
    UPDATE_RIGHTS= "usersUpdateRights",
    UPDATE_ALLOCATIONS= "usersUpdateAllocations",
    DELETE_ITEM = "usersDeleteItem",
    REFRESH_LIST = "usersRefreshList",
    GET_ACCOUNT = "accountGet",
    UPDATE_ACCOUNT = "accountUpdate",
    PASSWORD_RESET = "passwordReset",
}

enum UsersMutation {
    SET_RENDER_UPDATE = "usersListRenderNeeded",
    SET_LIST_RESULT = "usersListResult"
}

export {UsersActions, UsersMutation};
