import {OffsetModel} from "../../models/OffsetModel";

export function getElementOffset(el: HTMLElement): OffsetModel {

    try {

        // Return zeros for disconnected and hidden (display: none) elements (gh-2310)
        // Support: IE <=11 only
        // Running getBoundingClientRect on a
        // disconnected node in IE throws an error
        if (!el || !el.getClientRects().length) {
            return {top: 0, left: 0};
        }

        // Get document-relative position by adding viewport scroll to viewport-relative gBCR
        const rect = el.getBoundingClientRect();
        const win = el.ownerDocument.defaultView;
        if (win) {
            return {
                top: rect.top + win.scrollY,
                left: rect.left + win.scrollX,
            };
        }

        return rect;

    } catch (e) {

        return {top: 0, left: 0};

    }

}
