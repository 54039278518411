enum SubscriptionsActions {
    FETCH_LIST = "subscriptionsFetchList",
    GET_ITEM = "subscriptionsGetItem",
    CREATE_ITEM = "subscriptionsCreateItem",
    UPDATE_ITEM = "subscriptionsUpdateItem",
    DELETE_ITEM = "subscriptionsDeleteItem",
    REFRESH_LIST = "subscriptionsRefreshList",
}

enum SubscriptionsMutation {
    SET_RENDER_UPDATE = "subscriptionsListRenderNeeded",
    SET_LIST_RESULT = "subscriptionsListResult"
}

export {SubscriptionsActions, SubscriptionsMutation};
