enum ExampleActions {
    FETCH_LIST = "exampleFetchList",
    GET_ITEM = "exampleGetItem",
    CREATE_ITEM = "exampleCreateItem",
    UPDATE_ITEM = "exampleUpdateItem",
    DELETE_ITEM = "exampleDeleteItem",
    REFRESH_LIST = "exampleRefreshList",
}

enum ExampleMutation {
    SET_RENDER_UPDATE = "exampleListRenderNeeded",
    SET_LIST_RESULT = "exampleListResult"
}

export {ExampleActions, ExampleMutation};
