enum NavigationActions {
    FETCH_LIST = "navigationFetchList",
    GET_ITEM = "navigationGetItem",
    CREATE_ITEM = "navigationCreateItem",
    UPDATE_ITEM = "navigationUpdateItem",
    DELETE_ITEM = "navigationDeleteItem",
    REFRESH_LIST = "navigationRefreshList",
}

enum NavigationMutation {
    SET_RENDER_UPDATE = "navigationListRenderNeeded",
    SET_LIST_RESULT = "navigationListResult"
}

export {NavigationActions, NavigationMutation};
