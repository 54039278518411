import apiService from "@/core/services/apiService";

export type State = { classes: object };
import {Module, VuexModule, Mutation, Action} from "vuex-module-decorators";
import {Mutations} from "@/store/enums/store.enums";
import {ShipmentActions, ShipmentMutations} from "@/modules/shipment/store/enums";

@Module
export default class Store extends VuexModule {
    renderKey = 1;

    listParams = {
        page: 1,
        results: 10,
        search: null,
        sort: [],
        filter: {}
    };

    listCache = [];
    listTotal = 0;
    listFilterOptions = {};

    get getShipmentRenderKey() {
        return 'shipment-' + this.renderKey;
    }

    get getShipmentLastListResult() {
        return this.listCache;
    }

    get getShipmentLastFilterOptions() {
        return this.listFilterOptions;
    }

    get getShipmentLastListTotal() {
        return this.listTotal;
    }

    @Mutation
    [ShipmentMutations.SET_RENDER_UPDATE]() {
        this.renderKey++;
    }

    @Mutation
    [ShipmentMutations.SET_LIST_RESULT]({listParams, listTotal, listCache, listFilterOptions}) {
        this.listParams = listParams;
        this.listCache = listCache;
        this.listFilterOptions = listFilterOptions;
        this.listTotal = listTotal;
    }

    @Action
    [ShipmentActions.REFRESH_LIST]() {
        this.context.dispatch(ShipmentActions.FETCH_LIST, this.listParams);
    }

    @Action
    [ShipmentActions.FETCH_LIST](params) {

        return new Promise<void>((resolve, reject) => {

            apiService.post("shipment/list", params).then((response) => {

                const data = response.data || null;

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results into storage for reactive update if needed //
                this.context.commit(ShipmentMutations.SET_LIST_RESULT, {
                    listParams: params,
                    listTotal: data.results,
                    listCache: data.result,
                    listFilterOptions: data.filter
                });
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ShipmentActions.GET_ITEM](detailId) {

        return new Promise<void>((resolve, reject) => {

            apiService.get(`shipment/get/${detailId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ShipmentActions.UPDATE_SHIPMENT](payload) {

        return new Promise<void>((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`shipment/edit/${editId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results to dispatcher without mutation //
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ShipmentActions.CONFIRM_SHIPMENT](payload) {

        return new Promise<void>((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`shipment/confirm/${editId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(ShipmentActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ShipmentActions.DELETE_ITEM](editId) {

        return new Promise((resolve, reject) => {

            apiService.delete(`shipment/delete/${editId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(ShipmentActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ShipmentActions.UPLOAD_DATA](payload) {

        return new Promise<void>((resolve, reject) => {

            const {editId, files} = payload;

            apiService.post(`shipment/upload/${editId}`, files).then((response) => {

                const data = response.data || null;

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ShipmentActions.DELETE_FILE](file) {

        return new Promise((resolve, reject) => {

            const {shipmentFileId} = file;

            apiService.delete(`shipment/file/${shipmentFileId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(ShipmentActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

}
