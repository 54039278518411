export enum locales {
    DE = 'de',
    EN = 'en',
    FR = 'fr',
    IT = 'it',
    ES = 'es',
}

export const localesOptions = [
    {id: locales.DE, value: locales.DE, label: 'Deutsch', flag: 'germany.svg'},
    {id: locales.EN, value: locales.EN, label: 'English', flag: 'united-states.svg'},
    {id: locales.FR, value: locales.FR, label: 'Français', flag: 'france.svg'},
    {id: locales.IT, value: locales.IT, label: 'Italiano', flag: 'italy.svg'},
    {id: locales.IT, value: locales.IT, label: 'Español', flag: 'spain.svg'}
];

export const licenseOptions = [
    {id: 'trial', value: 'trial', label: 'trial'},
    {id: 'unlimited', value:'unlimited', label: 'unlimited'},
];

export enum countries {
    DE = 'DE',
    UK = 'UK',
    FR = 'FR',
    IT = 'IT',
    ES = 'ES',
    US = 'US',
    CH = 'CH',
    AT = 'AT',
    SE = 'SE',
    BE = 'BE',
    HR = 'HR',
    CY = 'CY',
    CZ = 'CZ',
    DK = 'DK',
    EE = 'EE',
    FI = 'FI',
    GR = 'GR',
    HU = 'HU',
    IE = 'IE',
    LV = 'LV',
    LT = 'LT',
    BG = 'BG',
    LU = 'LU',
    MA = 'MA',
    NL = 'NL',
    PL = 'PL',
    PT = 'PT',
    RO = 'RO',
    SK = 'SK',
    SI = 'SI',
}

export const countryOptions = [
    {id: countries.AT, value: countries.AT, label: 'Austria'},
    {id: countries.BE, value: countries.BE, label: 'Belgium'},
    {id: countries.BG, value: countries.BG, label: 'Bulgaria'},
    {id: countries.HR, value: countries.HR, label: 'Croatia'},
    {id: countries.CY, value: countries.CY, label: 'Cyprus'},
    {id: countries.CZ, value: countries.CZ, label: 'Czech Republic'},
    {id: countries.DK, value: countries.DK, label: 'Denmark'},
    {id: countries.EE, value: countries.EE, label: 'Estonia'},
    {id: countries.FI, value: countries.FI, label: 'Finland'},
    {id: countries.FR, value: countries.FR, label: 'France'},
    {id: countries.DE, value: countries.DE, label: 'Deutschland'},
    {id: countries.GR, value: countries.GR, label: 'Greece'},
    {id: countries.HU, value: countries.HU, label: 'Hungary'},
    {id: countries.IE, value: countries.IE, label: 'Ireland'},
    {id: countries.IT, value: countries.IT, label: 'Italy'},
    {id: countries.LV, value: countries.LV, label: 'Latvia'},
    {id: countries.LT, value: countries.LT, label: 'Lithuania'},
    {id: countries.LU, value: countries.LU, label: 'Luxembourg'},
    {id: countries.MA, value: countries.MA, label: 'Malta'},
    {id: countries.NL, value: countries.NL, label: 'Netherlands'},
    {id: countries.PL, value: countries.PL, label: 'Poland'},
    {id: countries.PT, value: countries.PT, label: 'Portugal'},
    {id: countries.RO, value: countries.RO, label: 'Romania'},
    {id: countries.SK, value: countries.SK, label: 'Slovakia'},
    {id: countries.SI, value: countries.SI, label: 'Slovenia'},
    {id: countries.ES, value: countries.ES, label: 'Spain'},
    {id: countries.SE, value: countries.SE, label: 'Sweden'},
    {id: countries.CH, value: countries.CH, label: 'Switzerland'},
    {id: countries.UK, value: countries.UK, label: 'United Kingdom'},
    {id: countries.US, value: countries.US, label: 'United States'},
];
