enum WarehousesActions {
    FETCH_LIST = "warehousesFetchList",
    GET_ITEM = "warehousesGetItem",
    CREATE_ITEM = "warehousesCreateItem",
    UPDATE_ITEM = "warehousesUpdateItem",
    DELETE_ITEM = "warehousesDeleteItem",
    REFRESH_LIST = "warehousesRefreshList",
    UPDATE_ACCOUNTS = "warehousesUpdateAccounts",
    UPDATE_SETTINGS = "warehousesUpdateSettings",

    FETCH_LOCATIONS = "warehousesFetchLocations",
    IMPORT_LOCATIONS = "warehousesImportLocations",
    GET_LOCATION = "warehousesGetLocation",
    CREATE_LOCATION = "warehousesCreateLocation",
    UPDATE_LOCATION = "warehousesUpdateLocation",
    DELETE_LOCATION = "warehousesDeleteLocation",
    REFRESH_LOCATIONS = "warehousesRefreshLocations",

    FETCH_PACKAGINGS = "warehousesFetchPackagings",
    IMPORT_PACKAGINGS = "warehousesImportPackagings",
    GET_PACKAGING = "warehousesGetPackaging",
    CREATE_PACKAGING = "warehousesCreatePackaging",
    UPDATE_PACKAGING = "warehousesUpdatePackaging",
    DELETE_PACKAGING = "warehousesDeletePackaging",
    REFRESH_PACKAGINGS = "warehousesRefreshPackagings",
    SEARCH_PACKAGINGS = "warehousesSearchPackagings",

    CHECK_DATA_CONSTRAINTS = "warehousesCheckDataConstraints",
    SEARCH_LOCATIONS = "warehousesSearchLocation",

}

enum WarehousesMutation {
    SET_RENDER_UPDATE = "warehousesListRenderNeeded",
    SET_LIST_RESULT = "warehousesListResult",
    SET_LOCATIONS_RESULT = "warehousesLocationsResult",
    SET_WAREHOUSE_RESULT = "warehousesSetWarehouse",
    SET_PACKAGING_RESULT = "warehousesSetPackaging",
}

export {WarehousesActions, WarehousesMutation};
