enum CoursesActions {
    FETCH_LIST = "coursesFetchList",
    GET_ITEM = "coursesGetItem",
    CREATE_ITEM = "coursesCreateItem",
    UPDATE_ITEM = "coursesUpdateItem",
    DELETE_ITEM = "coursesDeleteItem",
    REFRESH_LIST = "coursesRefreshList",
}

enum CoursesMutation {
    SET_RENDER_UPDATE = "coursesListRenderNeeded",
    SET_LIST_RESULT = "coursesListResult"
}

export {CoursesActions, CoursesMutation};
